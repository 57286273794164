import { ReactNode, useEffect, useState } from "react"
import { createPortal } from 'react-dom'
import classes from "./ModalContainer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons"

interface IProps {
    children: ReactNode
    isOpen: boolean
    background?: string
    fullScreen?: boolean
    noPadding?: boolean
    notCentered?: boolean
    fullScreenKeyboard?: boolean
    isNested?: boolean
    isNestedOpen?: boolean
    maxWidth?: number | string
    close?: () => void
    containerClasses?: string
    containerClassName?: string
    positionClassname?: string
    containerMargin?: string
    withCloseButton?: boolean
    isNewGallery?: boolean
}

const ModalContainer = ({ children, isOpen, background, fullScreen, noPadding, notCentered, fullScreenKeyboard, isNested, isNestedOpen, maxWidth, close, containerClasses, containerClassName, positionClassname, containerMargin, withCloseButton, isNewGallery }: IProps) => {
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (!isNested) {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
                if (isNewGallery) {
                    document.documentElement.style.overflow = 'hidden'
                }
            } else {
                document.body.style.overflow = 'auto'
                if (isNewGallery) {
                    document.documentElement.style.overflow = 'auto'
                }
            }
        }
        return () => {
            document.body.style.overflow = 'auto'
            if (isNewGallery) {
                document.documentElement.style.overflow = 'auto'
            }
        }
    }, [])

    useEffect(() => {
        if (isOpen) setIsReady(true)

        if (!isNested) {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
                if (isNewGallery) {
                    document.documentElement.style.overflow = 'hidden'
                }
            } else {
                document.body.style.overflow = 'auto'
                if (isNewGallery) {
                    document.documentElement.style.overflow = 'auto'
                }
            }
        }
    }, [isOpen, isNested, isNewGallery])

    return isReady ? createPortal(
        <div className={`${classes.container} ${isOpen ? classes.open : classes.close} ${isNestedOpen ? 'overflow-none' : 'overflow-auto'}`}>
            <div className={`relative min-h-full min-w-full ${fullScreen ? 'w-full h-full' : ''} flex ${positionClassname || 'md:items-center justify-center'}`}>
                {
                    withCloseButton &&
                    <span className="absolute right-0 top-0 text-white z-52 mr-2 mt-2">
                        <FontAwesomeIcon icon={faTimesCircle} size="2x" width="0" className="text-white cursor-pointer" onClick={close} />
                    </span>
                }
                <div className={`${classes.backdrop}`} style={{ justifyContent: notCentered ? 'flex-start' : 'center', backgroundColor: fullScreenKeyboard ? 'white' : undefined }} onClick={() => {
                    if (typeof close !== 'undefined') {
                        close()
                    }
                }}>
                </div>
                <div
                    className={`${containerClassName} ${classes.card} ${isOpen ? classes['card-open'] : ''} ${containerClasses} ${background || 'bg-white'} ${fullScreen ? 'w-full h-full' : fullScreenKeyboard ? 'w-full' : ''} ${noPadding ? '' : 'p-8'} ${fullScreen || fullScreenKeyboard ? 'px-0' : ''} rounded ${containerMargin || ''}`}
                    style={{
                        margin: fullScreenKeyboard ? undefined : notCentered ? '75px 0' : 0,
                        maxWidth,
                        maxHeight: !fullScreen && !fullScreenKeyboard ? '90vh' : 'unset',
                        overflowX: 'auto'
                    }}
                >
                    {children}
                </div>
            </div>
        </div>,
        document.body,
    ) : null
}

export default ModalContainer