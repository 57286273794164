import classes from './Input.module.css'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'

interface IProps {
    value: string | number
    placeholder?: string
    name: string
    onChange?: (e: React.ChangeEvent<{ name: string, value: string }>) => void
    onBlur?: (e: React.FocusEvent<{ name: string, value: string }>) => void
    type?: 'text' | 'number' | 'date' | 'password'
    className?: string
    endAdornment?: React.ReactNode
    startAdornment?: React.ReactNode
    error?: boolean
    errorText?: string
    helper?: string
    disabled?: boolean
    placeholder_color?: string
    border_color?: string
    outlined?: boolean
    select?: boolean
    /** use native select */
    native?: boolean
    children?: React.ReactNode
    textArea?: boolean
    autoComplete?: boolean
    /** url for autoComplete options */
    url?: string
    /** limit for options shown in auto complete, default 5 */
    limit?: number
    /** decide what happened when options selected */
    onSelected?: (option: any, name?: string) => void
    readOnly?: boolean
    /** for textarea */
    rows?: number
    rounded?: boolean
    agent?: boolean
    listing?: boolean
    clusterId?: string
    inputClassName?: string
    labelAlwaysUp?: boolean
    label?: string
}

let timeout: any;
const debounce = (func: () => void, wait: number = 1000) => {
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
    }
}

const Input = React.forwardRef(({ value, placeholder, name, onChange, onBlur, type, startAdornment, className, endAdornment, error, errorText, helper, disabled, placeholder_color, border_color, outlined, select, native, children, textArea, autoComplete, url, limit, onSelected, readOnly, rows, rounded, agent, listing, clusterId, inputClassName, labelAlwaysUp, label }: IProps, ref: React.Ref<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const inputRef = ref as React.RefObject<HTMLInputElement> || useRef<HTMLInputElement>(null)
    const selectRef = ref as React.RefObject<HTMLSelectElement> || useRef<HTMLSelectElement>(null)
    const textAreaRef = ref as React.RefObject<HTMLTextAreaElement> || useRef<HTMLTextAreaElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const [options, setOptions] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)
    const [isACOpen, setACOpen] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [cursor, setCursor] = useState(0)

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);

        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    const debounce = (func: () => void, wait: number = 1000) => {
        return function () {
            clearTimeout(timeout);
            timeout = setTimeout(func, wait);
        }
    }

    /**
     * Autocomplete stuff
     */

    const loadData = debounce(() => {
        if (autoComplete && url) {
            setLoading(true)
            if (agent) {
                if (listing) {
                    if (clusterId) {
                        const data = {
                            cluster_id: clusterId,
                            search: value
                        }
                        Axios.get(url, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            },
                            params: data
                        })
                            .then(res => res.data)
                            .then(data => setOptions(data))
                            .finally(() => setLoading(false))
                    }
                } else {
                    const data = {
                        search: value
                    }
                    Axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        },
                        params: data
                    })
                        .then(res => res.data)
                        .then(data => setOptions(data))
                        .finally(() => setLoading(false))
                }
            } else {
                Axios.get(url)
                    .then(res => res.data)
                    .then(data => setOptions(data))
                    .finally(() => setLoading(false))
            }
        }
    })

    useEffect(() => {
        if (isActive) {
            loadData()
        }
    }, [url, clusterId, value, isActive])

    // Render filtered options
    // Using memoized value to prevent re-render on every focus
    const filteredOptions = useMemo(() => {
        return options.filter(option => {
            let currentValue = value as string
            const cleanedValue = currentValue.trim().toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            const re = new RegExp(`${cleanedValue}`, 'gi')
            return re.test(option.label.trim().toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
        }).slice(0, limit || 5)
    }, [value, options])

    // Handle change on input for autocomplete
    const handleChange = (e: React.ChangeEvent<{ name: string, value: string }>) => {
        if (onChange) onChange(e)
        if (autoComplete) {
            if (!isACOpen) setACOpen(true)
            if (cursor !== 1) setCursor(1)
        }
    }

    // Handle click on input for autcomplete
    const handleClick = () => {
        if (autoComplete && !disabled) {
            setACOpen(true)
            if (cursor === 0 || value) setCursor(1)
            setIsActive(true)
        }
    }

    // Handle keydown for autocomplete
    const handleKeyDown = (e: React.KeyboardEvent) => {
        const key = e.key
        if (autoComplete) {
            switch (key) {
                case 'ArrowDown':
                    if (!isACOpen) setACOpen(true)
                    e.preventDefault()
                    if (cursor < (limit || 5)) {
                        setCursor(prev => prev + 1)
                    }
                    break
                case 'ArrowUp':
                    if (!isACOpen) setACOpen(true)
                    e.preventDefault()
                    if (cursor > 1) {
                        setCursor(prev => prev - 1)
                    }
                    break
                case 'Escape':
                    if (isACOpen) setACOpen(false)
                    break
                case 'Enter':
                    if (onSelected) onSelected(filteredOptions[cursor - 1], name)
                    setACOpen(false)
                    break
                case 'Tab':
                    if (isACOpen) setACOpen(false)
                    break
            }
        }
    }

    // Handle option selected for autocomplete
    const handleSelect = (option: any, currentCursor: number) => {
        if (onSelected) onSelected(option, name)
        setCursor(currentCursor)
        setACOpen(false)
        if (inputRef.current) inputRef.current.focus()
    }

    // Handle click outside of container for autocomplete
    const handleClickOutside = (e: any) => {
        if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
            setACOpen(false)
            setIsActive(false)
        }
    };

    return (
        <div className={`${outlined ? 'mb-6' : 'mb-8'} ${className}`}>
            <div ref={containerRef} className={`${classes['form-control']}`}>
                {
                    select ?
                        <select
                            ref={selectRef}
                            disabled={disabled}
                            value={value}
                            name={name}
                            onChange={onChange || undefined}
                            onBlur={onBlur || undefined}
                            className={`${classes['form-input']} ${endAdornment ? outlined ? 'pr-12' : 'pr-8' : 'pr-8'} ${startAdornment ? outlined ? 'pl-12' : 'pl-8' : ''} focus:outline-none text-sm ${outlined ? `${classes.outlined} py-4 px-3 border rounded` : 'border-b pb-1'} ${error ? classes.error : ''} transition-colors duration-100 ease-linear ${error ? 'border-error' : border_color || 'border-gray-input'} w-full appearance-none cursor-pointer ${disabled ? 'bg-gray-disabled' : 'bg-white'}`}
                        >
                            {children}
                        </select>
                        :
                        textArea ?
                            <textarea
                                ref={textAreaRef}
                                disabled={disabled}
                                value={value}
                                name={name}
                                onChange={onChange || undefined}
                                onBlur={onBlur || undefined}
                                readOnly={readOnly}
                                className={`${classes['form-input']} ${endAdornment ? outlined ? 'pr-12' : 'pr-8' : ''} focus:outline-none text-sm ${outlined ? `${classes.outlined} py-4 px-3 border rounded` : 'border-b pb-1'} transition-colors duration-100 ease-linear ${error ? classes.error : ''} ${error ? 'border-error' : border_color || 'border-gray-input'} w-full ${disabled ? 'bg-gray-disabled' : 'bg-white'}`}
                                rows={rows}
                            />
                            :
                            <input
                                ref={inputRef}
                                disabled={disabled}
                                type={type || 'text'}
                                placeholder={placeholder || ''}
                                value={value}
                                name={name}
                                onChange={handleChange}
                                onBlur={onBlur || undefined}
                                onKeyDown={handleKeyDown}
                                onClick={handleClick}
                                autoComplete={autoComplete ? 'off' : undefined}
                                readOnly={readOnly}
                                className={`${inputClassName || ''} ${rounded && 'rounded-md'} ${classes['form-input']} ${isActive ? classes['form-input-active'] : ''} ${endAdornment ? outlined ? 'pr-12' : 'pr-8' : isLoading ? 'pr-10' : ''} ${startAdornment ? outlined ? 'pl-12' : 'pl-8' : isLoading ? '' : ''} focus:outline-none text-sm ${outlined ? `${classes.outlined} py-4 px-3 border rounded` : 'border-b pb-1'} transition-colors duration-100 ease-linear ${error ? classes.error : ''} ${error ? 'border-error' : border_color || 'border-gray-input'} w-full ${disabled ? 'bg-gray-disabled' : 'bg-white'}`}
                            />
                }
                {
                    !agent &&
                    <label className={`${(value !== '' || labelAlwaysUp) ? classes.filled : ''} ${outlined ? classes.outlined : ''} ${error ? classes.error : ''} absolute left-0 text-sm ${error ? 'text-error' : placeholder_color ? placeholder_color : 'text-gray-c2'} transform duration-200 ease-in-out cursor-text pointer-events-none`}>
                        <span className={`w-full block border-b-2 border-transparent absolute bottom-0 left-0 ${classes['input-label']}`} />
                        <p>{label || placeholder}</p>
                    </label>
                }
                {
                    !!startAdornment &&
                    <span className={`absolute left-0 ${outlined ? 'top-0 h-full p-px' : 'mr-1'}`}>
                        {startAdornment}
                    </span>
                }
                {
                    endAdornment ?
                        <span className={`absolute right-0 ${outlined ? 'top-0 h-full p-px' : 'mr-1'}`}>
                            {endAdornment}
                        </span>
                        : select ?
                            <span className={`absolute right-0 mr-4 text-sm pointer-events-none ${classes['input-icon']} `}>
                                <FontAwesomeIcon icon="caret-down" width="0" size="sm" />
                            </span>
                            : isLoading ?
                                !disabled &&
                                <span className={`absolute h-full flex top-0 items-center right-0 mr-4`}>
                                    <span className={`loader ${classes['input-icon-size']}`} ></span>
                                </span>
                                : autoComplete ?
                                    <span className={`absolute right-0 mr-4 text-sm pointer-events-none ${classes['input-icon']} `}>
                                        <img src="/static/images/svg/chevron-down.svg" alt="dropdown icon" className={`px-1 ${isACOpen ? 'transform rotate-180' : ''}`} />
                                    </span>
                                    : null
                }
                {
                    options.length > 0 ?
                        <div className={`${classes['autocomplete-options']} absolute w-full z-20 bg-white rounded shadow ${isACOpen ? classes['autocomplete-options-open'] : ''}`}>
                            {
                                filteredOptions.map((option, idx) =>
                                    <div key={option.id} onClick={() => handleSelect(option, idx + 1)} onMouseOver={() => setCursor(idx + 1)} className={`py-3 px-2 cursor-pointer ${cursor === (idx + 1) ? 'bg-main text-white' : ''} hover:bg-main hover:text-white`}>
                                        {option.label}
                                    </div>
                                )
                            }
                        </div>
                        : null
                }
            </div>
            {
                error && errorText ?
                    <p className={`text-xs text-error mt-1 ${outlined ? 'ml-2' : ''}`}>{errorText}</p>
                    : !!helper ?
                        <p className={`text-xs mt-1 ${outlined ? 'ml-2' : ''}`}>{helper}</p>
                        : null
            }
        </div>
    )
})

export default Input